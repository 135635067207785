.bannerImage {
    background-color: #2b861411;
    height: 600px;
}


.captionsTitle span b {
    color: #2a8614 ;
}

.captionsPara {
    border-left: #2a8614 solid 2px;
    padding-left: .7rem;
}

.demoButton {
    margin-top: 1.4rem;
    margin-bottom: 2.8rem;
}


.invest-row {
    background-color: #685dbd;
    padding: 1rem;
    

    .invest-row-section {
        justify-content: center !important;
    }
}

.investors-desc {
        color: white;
        margin-right: 5rem;
        display: inline;
}

.invest-button {
    border-color: white;
    color: white;
    padding: 0.25rem;
    background-color: rgba(255, 255, 255, 0.151);
}


@media only screen and (min-width:767px){
    .bannerImage::before {
        content: "";
        background-image: url('./images/background2.svg');
        background-size: auto;
        position: absolute;
        top: 10px;
        height: 710px;
        width: 750px;
        z-index: -1;
    }

    .captions {
        padding-right: 5rem;
    }

    .captions section {
        text-align: left;
    }

    .captionsTitle {
        font-size: 3.8rem;
        font-weight: 300;
    }

    .small-device-video-player {
        display: none;
    }

}


@media only screen and (max-width: 992px){
   
    .investors-desc {
        display: block !important;
        margin-right: 0;
        margin-bottom: 1.2rem;
    }

    .bannerImage {
        display: none !important;
    }

    .small-device-video-player {
        display: inline-block;
        margin-left: 1rem;
    }

    .captions {
        margin-top: 40px;
    }

    .invest-button {
        margin: auto !important;
        display: block !important;
    }
}



