
@media only screen and (max-width:767px){
    .product {
       margin-top: 0;
    }
 
    .main-head section {
       text-align: center; 
    }
    
    .title-row {
       padding-top: 3.5rem;
    }
 
    .main-head .title {
       font-size: 2.4rem;
       line-height: 2.7rem;
    }
    
    .main-head .title strong {
       margin-top: 0;
    }
 
    .product-list {
       margin-bottom: 5px;
    }
    .product-list .ontime-card {
       padding: 1.5rem;
    }
 
    .product-row {
       margin-top: 0;
    }
 
    .product-list .ontime-card .subtitle {
       font-size: 1.6rem;
       line-height: 2rem;
       text-align: center;
    }
 
    .product-list .ontime-card .highlight-text {
       line-height: 2rem;
    }
 
    .product-list .ontime-card .para {
       text-align: center !important;
    }
 
    .margin-botton-sm {
       margin-bottom: 10px;
    }
 
    .image-section {
       margin: auto;
    }
 
    .more-btn-section {
       text-align: center !important;
    }
    
 }