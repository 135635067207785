@media only screen and (max-width:767px) {

    .contact-section {
        text-align: center;
    }

    .contact-section .title {
        font-size: 2.4rem;
        line-height: 2.5rem;
     }

     .contact-section .title strong {
        margin-top: 0 !important;
     }

     .contact-section .small-screens-margin {
        margin-top: 1rem;
     }
}