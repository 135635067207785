.pricing-features {
    margin-top: 2.8rem;
    margin-bottom: 1.75rem;
    padding-bottom: 1.75rem;
    border-bottom: 1px solid rgba(128, 128, 128, 0.541);
}

.pricing ul {
    list-style: none;
    margin-left: 0;
    padding-left: 1.2em;
    text-indent: -1.2em;
}


.price-section .price-figure .price-sign {
    font-size: 1.2rem;
    vertical-align: super;
    font-weight: lighter;
}

.price-button {
    padding: 0.7rem;
    text-align: end;
    margin-top: 1rem;
}

.custom-price-section .price-figure .email {
    font-size: 1.4rem !important;
}

.price-section .price-figure {
    font-size: 3.5rem;
    color: #2a8614;
}

.price-section .price-month {
    font-size: 1.4rem;
    color: gray;
}

.price-highlight {
    background-color: rgb(21, 22, 20);
    padding: 1.4rem;
    border-radius: .7rem;
}

.price-highlight .price-model strong {
    color: white !important;
}

.price-highlight .para {
    color: white !important;
}

.price-model strong {
    margin-bottom: 0;
}

.price-btn-highlight button {
    background-color: rgb(21, 22, 20);
    color: white !important;
}

.custom-price-section .price-figure {
    font-size: 2.1rem;
    color: #2a8614;
}

.custom-price-section .price-month {
    font-size: 1rem;
    color: gray;
}

.fine-print {
    color: grey;
    font-weight: lighter;
}



@media only screen and (min-width:768px) {
    .pricing {
        margin-top: 9.1rem;
    }

    .pricing-title {
        margin-bottom: 3.5rem;
    }

    .price-button {
        padding: 0.7rem;
        text-align: center;
        margin-top: 1rem;
    }


    .pricing ul li {
        line-height: 2;
    }

    .pricing ul li::before {
        padding-top: 0.2rem;
        color: #2a8614;
        font-family: "fontawesome";
        content: "\f058";
        float: left;
        width: 1.4em;
    }
}