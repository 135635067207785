.contact-section {
    margin-top: 9.1rem;
    padding: 2.8rem;
    background-color: #2b861411;
}

.contact-section section {
    margin-bottom: 1.4rem;
}

.form-select {
    border: 1px solid #2a8614;
    color: grey;
    font-weight: normal;
    font-size: 1.2rem;
}

.form-select:focus {
  outline:none !important;
  border: 1px solid #2a8614;
  outline-style: none;
  box-shadow: none;
}

