@media only screen and (max-width:767px) {
    .pricing {
        margin-top: 1.5rem;
    }


    .pricing-title {
        margin-bottom: 1.5rem;
    }

    .pricing-title .title {
        font-size: 2.4rem;
        line-height: 2.7rem;
        text-align: center;
        display: block;
    }

    .pricing-title .title strong {
        margin-top: 0 !important;
    }

    .price-model .subtitle {
        text-align: center;
    }

    .pricing ul li .text {
        line-height: 2;
        text-align: left !important;
    }

    .pricing ul li::before {
        padding-top: 0.4rem;
        color: #2a8614;
        font-family: "fontawesome";
        content: "\f058";
        float: left;
        width: 1.4em;
    }

    .price-section {
        text-align: center;
    }

    .fine-print {
        text-align: center;
    }
    
    .price-button {
        text-align: center;
    }

    .margin-botton-sm {
        margin-bottom: 10px;
     }
     
}