.demo-container {
    padding-top: 7rem;
}

.demo-form-container {
    margin-top: 7rem;
    margin-bottom: 7rem;
}

.demo-form-row {
    margin-top: 1.2rem;
}

.demo-form-row .extra-margin {
    margin-top: .7rem;
}

.demo-form-row .form-select {
    border: 1px solid #2a8614;
    color: grey;
}

.demo-form-container .form-select:focus {
  outline:none !important;
  outline-style: none;
  box-shadow: none;
}

.demo-form-row .form-select.is-invalid, .was-validated .form-select:invalid {
    border-color: #dc3545;
}

.alert-container {
    margin-top: 1.4rem;
}

.imageSection {
    margin: auto;
}

input[type="checkbox"] {
    position: relative;
    width: 1.5em;
    height: 1.5em;
    color: black;
    border: 1px solid gra;
    border-radius: 4px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}

input[type="checkbox"]::before {
    position: absolute;
    content: '';
    display: block;
    top: 5px;
    left: 9px;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
}

input[type="checkbox"]:checked {
    color: white;
    border-color: green;
    background: green;
}

input[type="checkbox"]:checked::before {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.termsnconditions {
    font-weight: lighter;
    margin-left: 1rem;
    font-size: 1.2rem;
    vertical-align: top;
}

.card-container {
    width: 1.2em;
}

@media only screen and (max-width: 767px) {

    .tnc-container {
        text-align: left;
    }

    .tnc-container label {
        display: inline;
    }

    .imageSection {
        display: none;
    }
}