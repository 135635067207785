.video-btn-parent {
    position: relative;
}

.video-btn {
    display: inline-block;
}

.far {
    font-style: normal;
    font-family: "fontawesome";
    color: #2a8614;
}

.play-btn {
    font-size: 30px;
    background: #ffffff;
    color: #01a479;
    line-height: 70px;
    width: 70px;
    height: 70px;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    text-align: center;
    cursor: pointer;
    z-index: 15;
    position: relative;
    display: inline-block;
    -webkit-transition: .15s ease-in-out;
    transition: .15s ease-in-out;
    -webkit-transition-property: color, background, border-color;
    transition-property: color, background, border-color;
}

.video-btn-pos {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 99;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
}

.video-btn .spinner-eff {
    position: absolute;
    width: 200px;
    height: 200px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
}

.spinner-eff .circle-1 {
    width: 100%;
    height: 100%;
    background-color: #01a479;
    top: 0;
    animation: pulse 1.6s linear 0s infinite;
}

.spinner-eff .spinner-circle {
    border-radius: 100px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    transform: scale(1);
    transform-origin: center center;
}

.spinner-eff .circle-2 {
    width: 66.6%;
    height: 66.6%;
    background-color: #01a479;
    top: 16.5%;
    animation: pulse-2 1.6s linear 0s infinite;
}

@keyframes pulse {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.3);
      opacity: 0;
    }
    100% {
      transform: scale(1.3);
      opacity: 0;
    }
  }
  @keyframes pulse-2 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1.3);
      opacity: 0;
    }
  }

.modal-content {
    background: none !important;
    border: none !important;
}

.modal-header {
    padding: 0 !important;
    border: none !important;
}