@media only screen and (max-width: 767px) {

    .nav-btns-small {
        color: #2a8614 !important;
    }
    .nav-btns {
        display: none;
    }
    .navbar-brand {
        margin-top: 0rem !important;
    }
    
    .navbar-collapse {
        margin-top: 20px;
    }
}