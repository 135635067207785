.demo-form-row {
    margin-top: 1.2rem;
}

.demo-form-row .extra-margin {
    margin-top: .7rem;
}

.demo-form-row .form-select {
    border: 1px solid #2a8614;
    color: grey;
}

.demo-form-row .form-select.is-invalid, .was-validated .form-select:invalid {
    border-color: #dc3545;
}


.demo-form-container .form-select:focus {
    outline: none !important;
    outline-style: none;
    box-shadow: none;
}

.alert-container {
    margin-top: 1.4rem;
}

.imageSection {
    margin: auto;
}

.demo-video-container {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

@media only screen and (max-width: 767px) {
    .demo-container {
        padding-top: 2rem;
    }

    .demo-container section {
        text-align: center;
    }

    .demo-container .title {
        line-height: 2.7rem;
    }

    .demo-container .title strong {
        margin-top: 0;
    }

    .demo-form-container {
        margin-top: 2rem;
        margin-bottom: 7rem;
        text-align: center;
    }
}


@media only screen and (min-width: 767px) {

    .demo-container {
        padding-top: 7rem;
    }


    .demo-form-container {
        margin-top: 6rem;
        margin-bottom: 7rem;
    }
}