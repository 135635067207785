.ontime-navbar .nav-link {
    color: #2a8614;
    padding-right: .7rem !important;
    padding-left: .7rem !important;
    margin-right: 15px !important;
}

.ontime-navbar .nav-link.active {
    color: #2a8614 !important;
    background-color: #2b861411;
    border-radius: .3rem;
}

.ontime-navbar .nav-link:hover{
    color: #2a8614 !important;
}

.ontime-navbar .nav-link.consulting {
    color: #8678EF !important;
    background-color: #dbd8f565;
    border-radius: .3rem;
}

.ontime-navbar .nav-link.consulting:hover {
    color: #6c5ed4 !important;
}

.ontime-navbar.navbar {
    padding-top: .7rem !important;
    padding-bottom: .7rem !important;
    background-color: white;
}

.navbar-brand {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: -0.7rem !important;
}

.nav-btns button {
    margin-right: 1rem;
}

@media only screen and (min-width: 993px) {
    .nav-btns-small {
        display: none;
        color: #2a8614 !important;
    }
}