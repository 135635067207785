@media only screen and (min-width: 768px) and (max-width: 992px){


    .footer-logo section {
        width: fit-content;
        margin: auto;
    }

    
    
}

