@media only screen and (max-width: 767px){
    
    .captions section {
        text-align: center;
    }

    .text {
        text-align: center !important;
    }

    .captionsTitle {
        font-size: 3.0rem;
        font-weight: 300;
        line-height: 3.4rem;
    }

    .captionsPara {
        border-right: #2a8614 solid 2px;
        padding-right: .7rem;
    }
    
}