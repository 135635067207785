@media only screen and (max-width:767px) {
    .footer-container {
        text-align: center !important;
    }

    .footer-logo img {
        margin: auto;
    }
}

