button:focus {
  outline: 0 !important;
  box-shadow: none;
}

*:focus {outline:none !important}

.content {
  min-height: 80vH;
}

