.payment-container {
    margin-top: 7rem;
}

.payment-details-container {
    margin-top: 5rem;
}

.payment-sidebar {
    background-color: #2b861411;
    padding: 3rem;
    border-radius: 0.2rem;
}

.checkout-price-text {
    font-size: 2rem;
    color: gray;
}

.checkout-price-title {
    color: #2a8614;
}

.checkout-item-area {
    margin-top: 9rem;
    color: gray;
}

.checkout-item-spacing {
    display: inline-block;
    width: 8rem;
}

.checkout-subscribtion-area {
    margin-top: 1rem;
    color: #2a8614;
}

.checkout-subscribtion-text {
    font-size: 0.8rem;
}

.payment-status-image {
    width: 4rem;
    height: 4rem;
    margin-bottom: 2rem;
    display: inline-block;
}

.payment-status-title {
    color: #2a8614;
    display: inline;
    font-size: 2.5rem;
}

#payment-message {
    color: #2a8614;
}