input, textarea {
    padding: .5rem;
    font-size: 1.2rem;
    border: 1px solid #2a8614;
    color: gray;
    border-radius: 0.2rem;
    font-weight: normal;
    width: 100%;
}

input::placeholder , textarea::placeholder {
    font-weight: lighter;
}

textarea {
    resize: none;
}

.error-overlay {  
    background-color: rgba(255, 100, 100, 0.85);
    padding: 2px 10px;
    color: white;
    border-radius: .2rem;
}

.overlay-positioner {
    width: 1px;
}