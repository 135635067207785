strong {
    font-weight: bold;
    display: block;
    color: #2a8614;
    margin-top: 0.4rem;
    margin-bottom: 0.7rem;
    line-height: 2.7rem;
}

.title {
    font-size: 2.4rem;
    line-height: 2.4rem;
}

.subtitle {
    font-size: 2.1rem;
    text-align: left;
    display: block;
}