button {
    background-color: #2b861411;
    border: solid #2a8614 thin;
    border-radius: 0.3rem;
    color: #2a8614;
    padding-right: .7rem !important;
    padding-left: .7rem !important;
}

.large {
    padding-right: 1.4rem !important;
    padding-left: 1.4rem !important;
    padding-top: .7rem !important;
    padding-bottom: .7rem !important;
}

.borderless {
    border: none;
    padding: 0.7rem;
}

.ripple {
    background-position: center;
    transition: background 0.8s;
}

.ripple:hover {
    background: #2b861411 radial-gradient(circle, transparent 1%, #2b861411 1%) center/15000%;
}

.ripple:active {
    background-color: #2b861411;
    background-size: 100%;
    transition: background 0s;
}