@media only screen and (min-width: 768px) and (max-width: 992px) {

    .nav-btns-small {
        color: #2a8614 !important;
    }
    .nav-btns {
        display: none;
    }
    .navbar-brand {
        margin-top: 0rem !important;
    }
    
    .navbar-collapse {
        margin-top: 20px;
    }
}