.footer-container {
    background-color: rgb(21, 22, 20);
    position: relative;
}

.footer-container .footer-col {
    padding: 1.4rem;
    padding-top: 3.5rem;
}

.footer-container .white-text {
    color: white;
}

.footer-links {
    color: white;
    text-decoration: none;
}

.footer-links ul {
    list-style-type: none;
    padding-left: 0rem !important;
}

.footer-links ul li {
    margin-bottom: .4rem;
}

.footer-links ul li a {
    color: white;
}   

.footer-text {
    font-size: .9rem;
    margin-top: .7rem;
}

.copyright-text {
    padding-bottom: 3.5rem;
}
