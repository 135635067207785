.text {
    font-size: 1.2rem;
    display: block;
    text-align: left;
}
.thin {
    font-weight: 300;
}
.light {
    font-weight: lighter;
}
.para {
    font-size: 1rem;
    text-align: justify;
    font-weight: 300;
    color: gray;
}

.heavy {
    font-weight: bold;
}