
.text-section {
   margin-top: 1rem;
}

.text-section span {
   text-align: left !important;
}

.product-list {
   margin-top: 3rem;
}

.more-btn-section {
   margin-top: 1rem;
   text-align: right;
}

.image-section {
   width: 35%;
   height: 35%;
   margin-bottom: 1.5rem;
}



@media only screen and (min-width:767px){
   .product {
      margin-top: .7rem;
   }   
   
   .main-head section {
      text-align: left; 
   }
   
   .title-row {
      padding-top: 3.5rem;
      margin-bottom: 5rem;
   }

   .product-row {
      margin-top: 2.1rem;
   }
   
}